import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  IconButton,
  Button,
} from "@mui/material";
import { InfoOutlined, OpenInNewOutlined } from "@mui/icons-material";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import EmailOpenIcon from "@mui/icons-material/DraftsOutlined";
import CallIcon from "@mui/icons-material/CallOutlined";
import ChatIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { decodeHtmlEntities } from "../utils/helpers";
import UserImage from "./userImage";
import ClientTheme from "../core/clientTheme";
import TagIcon from "@mui/icons-material/LocalOfferOutlined";
import { favoritesEnabled } from "../config";
import FavouriteBtn from "./favouriteBtn";
import { useWindowSize } from "./windowSize";

export default function ListItem(props) {
  const clientTheme = { ...ClientTheme() };
  let listInfoConfig, listTitleConfig;
  const { width } = useWindowSize();

  if (props?.owner === "client") {
    listInfoConfig = {
      owner: "client",
      variant: "body2",
      color: "clientText.main",
    };
    listTitleConfig = {
      owner: "client",
      variant: "subtitle1",
      color: "clientText.main",
      family: "secondary",
      lh: "medium",
      weight: "medium",
    };
  } else if (props?.owner === "admin") {
    listInfoConfig = {
      owner: "admin",
      variant: "body2",
      color: "adminText.main",
    };
    listTitleConfig = {
      owner: "admin",
      variant: "subtitle1",
      color: "clientText.main",
      lh: "medium",
      weight: "medium",
    };
  }

  const listInfo = (value) => {
    return (
      <Typography
        owner={listInfoConfig.owner}
        variant={listInfoConfig.variant}
        color={listInfoConfig.color}
      >
        {value}
      </Typography>
    );
  };

  const listTitle = (value) => {
    return (
      <Typography
        className="list-item-title"
        owner={listTitleConfig.owner}
        variant={listTitleConfig.variant}
        color={listTitleConfig.color}
        family={listTitleConfig.family}
        lh={listTitleConfig.lh}
        weight={listTitleConfig.weight}
      >
        {value}
      </Typography>
    );
  };

  const accordInfoBody = (value) => {
    return (
      <Typography
        owner={listInfoConfig.owner}
        variant={listInfoConfig.variant}
        color={listInfoConfig.color}
        dangerouslySetInnerHTML={{ __html: value }}
      ></Typography>
    );
  };

  return (
    <Accordion
      expanded={props.active}
      disableGutters
      className={`px-0 ${props.item.className}-list-item list-item`}
    >
      <AccordionSummary
        className="px-0 list-item-summary"
        sx={{
          "&, &:hover": {
            cursor: "default !important",
          },
        }}
      >
        <Box className="list-item-container w-100">
          <Row className={`list-item-row d-flex align-items-center ${(props.item.className === "document" || props.item.className === "link") && (width < 576) ? 'gx-0' : ''}`}>
            {props.idEnabled && (
              <Col
                xs={1}
                className={props.variant === "notification" ? "ps-4" : ""}
              >
                {listInfo(props?.item.id)}
              </Col>
            )}
            {props.avatar && (
              <Col className="avatar-container" xs={2} md={1}>
                <UserImage
                  className="ratio ratio-1x1 avatar"
                  owner="client"
                  sub_variant="user-image"
                  name={props?.item.title}
                  image={props?.item.user_picture}
                />
              </Col>
            )}
            {props?.dateEnabled && (
              <Col
                md={props.variant === "notification" ? 1 : 2}
                className={`d-none d-md-block ${props.variant === "notification" ? "me-4" : ""
                  }`}
              >
                <Row className="w-100">
                  <Col xs={12}>{listInfo(props?.item.dateCreated)}</Col>
                </Row>
              </Col>
            )}
            <Col xs={1} className="flex-grow-1 list-item-title-container">
              <Row className="w-100">
                <Col xs={12}>
                  {listTitle(decodeHtmlEntities(props?.item.title))}
                </Col>
              </Row>

              {props.subEnabled && (
                <Row className="w-100">
                  <Col xs={12} className="list-subtitle">
                    <Typography
                      owner={listInfoConfig.owner}
                      variant={listInfoConfig.variant}
                      color={listInfoConfig.color}
                    >
                      {props?.item.subtitle}
                    </Typography>
                  </Col>
                </Row>
              )}
            </Col>
            {props?.categoryEnabled && (
              <Col xs="auto">
                <Chip
                  className="category-chip"
                  size="small"
                  variant="default"
                  owner={props.owner}
                  role="metaData"
                  label={
                    <Typography
                      owner={listInfoConfig.owner}
                      variant="caption"
                      color={
                        props.owner === "client"
                          ? "clientChipText.main"
                          : listInfoConfig.color
                      }
                      family={listInfoConfig.family}
                      lh={listTitleConfig.lh}
                      weight={listInfoConfig.weight}
                    >
                      {clientTheme.components.chip.icon && (
                        <TagIcon className="me-1" sx={{ fontSize: "0.9rem" }} />
                      )}
                      {props?.item?.category_label
                        ? props?.item?.category_label
                        : "Uncategorized"}
                    </Typography>
                  }
                />
              </Col>
            )}
            {props?.infoBtn && (
              <Col
                xs={1}
                className="d-flex align-items-center justify-content-center"
              >
                {props.owner === "client" ? (
                  <Button
                    variant="listItemBtn"
                    owner="client"
                    mode="default"
                    disabled={props.infoDisabled}
                    onClick={() => props.handleInfo()}
                  >
                    <InfoOutlined sx={{ fontSize: "1.2rem" }} />
                  </Button>
                ) : (
                  <IconButton
                    color="adminPrimary"
                    size="small"
                    disabled={props.infoDisabled}
                    onClick={() => props.handleInfo()}
                  >
                    <InfoOutlined sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                )}
              </Col>
            )}
            {props?.copyBtn && (
              <Col
                xs={1}
                className="d-flex align-items-center justify-content-center"
              >
                {props.owner === "client" ? (
                  <Button
                    variant="listItemBtn"
                    owner="client"
                    mode="default"
                    onClick={() => props.handleCopy()}
                  >
                    <CopyIcon sx={{ fontSize: "1.2rem" }} />
                  </Button>
                ) : (
                  <IconButton
                    color="adminPrimary"
                    size="small"
                    onClick={() => props.handleCopy()}
                  >
                    <CopyIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                )}
              </Col>
            )}
            {props?.phoneBtn && (
              <Col
                md={1}
                className="d-flex align-items-center justify-content-center d-none d-md-block"
              >
                {props.owner === "client" ? (
                  <Button
                    variant="listItemBtn"
                    owner="client"
                    mode="default"
                    disabled={props?.item.phone_number === ""}
                    onClick={() =>
                      props.handlePhone() ? props.handlePhone() : ""
                    }
                  >
                    <CallIcon sx={{ fontSize: "1.2rem" }} />
                  </Button>
                ) : (
                  <IconButton
                    color="adminPrimary"
                    size="small"
                    disabled={props?.item.phone_number === ""}
                    onClick={() =>
                      props.handlePhone() ? props.handlePhone() : ""
                    }
                  >
                    <CallIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                )}
              </Col>
            )}
            {props?.chatBtn && (
              <Col
                md={1}
                className="d-flex align-items-center justify-content-center d-none d-md-block"
              >
                {props.owner === "client" ? (
                  <Button
                    variant="listItemBtn"
                    owner="client"
                    mode="default"
                    // disabled={props?.item.}
                    onClick={() =>
                      props.handleChat() ? props.handleChat() : ""
                    }
                  >
                    <ChatIcon sx={{ fontSize: "1.2rem" }} />
                  </Button>
                ) : (
                  <IconButton
                    color="adminPrimary"
                    size="small"
                    // disabled={props?.item.}
                    onClick={() =>
                      props.handleChat() ? props.handleChat() : ""
                    }
                  >
                    <ChatIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                )}
              </Col>
            )}
            {props?.emailBtn && (
              <Col
                xs={props.variant === "notification" ? 2 : 1}
                sm={
                  props.variant === "notification"
                    ? 1
                    : props.variant === "contacts"
                      ? 1
                      : "auto"
                }
                className={`d-flex align-items-center justify-content-center ${props.variant === "contacts" ? "d-none d-md-block" : ""
                  }`}
              >
                {props.owner === "client" ? (
                  <Button
                    variant="listItemBtn"
                    owner="client"
                    mode="default"
                    disabled={props?.emailStatus}
                    onClick={() => {
                      if (typeof props.handleEmail !== "undefined") {
                        props.handleEmail();
                      }
                    }}
                  >
                    {props?.emailStatus ? (
                      <EmailOpenIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <EmailIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </Button>
                ) : (
                  <IconButton
                    color="adminPrimary"
                    size="small"
                    disabled={props?.emailStatus}
                    onClick={() => {
                      if (typeof props.handleEmail !== "undefined") {
                        props.handleEmail();
                      }
                    }}
                  >
                    {props?.emailStatus ? (
                      <EmailOpenIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <EmailIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </IconButton>
                )}
              </Col>
            )}
            {((props.owner === "client" && favoritesEnabled) ||
              props.variant === "notification") && (
                <FavouriteBtn
                  owner={props.owner}
                  xs="1"
                  className="justify-content-center"
                  listItem={true}
                  contentType={props.contentType}
                  id={props.item.id}
                />
              )}
            {props?.openBtn && (
              <Col
                xs={props.variant === "notification" ? 2 : 1}
                sm={props.variant === "notification" ? 1 : 1}
                className="d-flex align-items-center justify-content-center"
              >
                {props.owner === "client" ? (
                  <Button
                    variant="listItemBtn"
                    owner="client"
                    mode="default"
                    disabled={props.openBtnDisabled}
                    onClick={() => props.handleOpen()}
                  >
                    <OpenInNewOutlined sx={{ fontSize: "1.2rem" }} />
                  </Button>
                ) : (
                  <IconButton
                    color="adminPrimary"
                    size="small"
                    disabled={props.openBtnDisabled}
                    onClick={() => props.handleOpen()}
                  >
                    <OpenInNewOutlined sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                )}
              </Col>
            )}
            {props?.children}
          </Row>
        </Box>
      </AccordionSummary>
      {props.accordInfo && (
        <AccordionDetails className="list-item-accordion">
          {accordInfoBody(props.accordInfoBody)}
        </AccordionDetails>
      )}
    </Accordion>
  );
}
