import React, { useState, useRef } from 'react';

// Modules

// App


// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Alert, Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import BackButton from './backButton';

export default function SelectedIceVideo(props) {
  const [isError, setError] = useState(null);
  const video = useRef(null);

  console.log('props.selectedMedia?.links: ', props.selectedMedia?.links);

  const onSelect = () => {
    const data = {
      external: true,
      url: props.selectedMedia?.links.cdnLinks[2],
      mid: `ext-${props?.selectedMedia.fileId}`,
      timestamp: null,
      poster: props.selectedMedia?.videoAdditionalFileLinks[0]?.posterFrameLink,
      assetId: props?.selectedMedia.assetId,
      fileId: props?.selectedMedia.fileId,
      listingId: props.listingId,
      listingName: props.listingName,
      iceMedia: props.selectedMedia,
    };

    props.onMediaInsert(data);
  }

  return (
    <>
      {isError ? (
        <>
          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
                <BackButton label={'Back'} onCancel={() =>  props.onCancel(true)} />
              </div>
            </Col>
          </Row>


          <Row>
            <Col>
              <Alert
                variant='filled'
                severity='error'
                onClose={() => {
                  setError(null);
                }}
              >
                <p>{isError}</p>
              </Alert>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
                <BackButton label={'Back'} onCancel={() =>  props.onCancel(true)} />
                {!props.isNotForInsert && (
                  <Stack direction='row' spacing={2} textAlign='center'>
                    <Button variant='contained' color='secondary' endIcon={<DoneIcon />} onClick={() => {
                      onSelect();
                    }} >Select</Button>

                  </Stack>
                )}
              </div>
            </Col>
          </Row>

          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className='text-center'>
                <video
                  ref={video}
                  poster={props?.selectedMedia?.videoAdditionalFileLinks[0]?.posterFrameLink}
                  className='video-item'
                  controls={true}
                  src={props.selectedMedia?.links?.cdnLinks[0]} // 0: 480p, 1: 720p, 2 && original >= 1080p
                  onError={(e) => {
                    setError(`Failed to load video`);
                  }}>
                  Your browser does not support the video element.
                </video>
              </div>
            </Col>
            <Col sm={12} md={6} className={'mb-3 info-container'}>
            <div className="wrapper">
              <div className="form-group">
                {props.selectedMedia?.mid && (
                  <Row className="mb-2 align-items-center info-row">
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Asset ID
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.assetId}
                      </Typography>
                    </Col>
                  </Row>
                )}

                {props.selectedMedia?.fileId && (
                  <Row className="mb-2 align-items-center info-row">
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        File ID
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.fileId}
                      </Typography>
                    </Col>
                  </Row>
                )}

                {props.selectedMedia?.assetId && (
                  <Row className="mb-2 align-items-center info-row">
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Asset ID
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.assetId}
                      </Typography>
                    </Col>
                  </Row>
                )}

                {props.selectedMedia?.privateId && (
                  <Row className="mb-2 align-items-center info-row">
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Private ID
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.privateId}
                      </Typography>
                    </Col>
                  </Row>
                )}

                {props.selectedMedia?.publicMediaId && (
                  <Row className="mb-2 align-items-center info-row">
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Public Media ID
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.publicMediaId}
                      </Typography>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};