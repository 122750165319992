import { CapacityIcon } from '../../../icons/icons'

/**
 * Build styles
 */
require('./index.css').toString();

export default class HotelCapacity {

  static get toolbox() {
    return {
      icon: CapacityIcon,
      title: 'Hotel capacity',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      br: true,
    };
  }

  static get DEFAULT_GUEST_ROOMS() {
    return '#'
  }

  static get DEFAULT_MEETING_ROOMS() {
    return '#'
  }

  static get DEFAULT_MAX_CAP() {
    return '#'
  }

  static get DEFAULT_EXHIBIT_SPACE() {
    return '#'
  }


  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      /**
       * Standard editor styles
       */
      customInput: 'editor-custom-input',
      /**
       * Tool's classes
       */
      container: 'cdx-hotel-capacity-container',
      contentContainer: 'cdx-hotel-capacity-content-container',
      wrapper: 'cdx-hotel-capacity',
      input: 'cdx-hotel-capacity-input',
      label: 'cdx-hotel-capacity-label',
      group: 'cdx-hotel-capacity-group',
      guestRoom: 'hotel-guest-room',
      meetingRooms: 'hotel-meeting-rooms',
      maxCap: 'hotel-max-cap',
      exhibitSpace: 'hotel-exhibit-space',
      suites: 'hotel-suites',
      familyRooms: 'hotel-family-rooms',
      restaurants: 'hotel-restaurants',
      bars: 'hotel-bars',
      spa: 'hotel-spa',
      gym: 'hotel-gym',
      pool: 'hotel-pool',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;


    this._data = {
      guestRoom: data.guestRoom || '',
      meetingRooms: data.meetingRooms || '',
      maxCap: data.maxCap || '',
      exhibitSpace: data.exhibitSpace || '',
      suites: data.suites || '',
      familyRooms: data.familyRooms || '',
      restaurants: data.restaurants || '',
      bars: data.bars || '',
      spa: data.spa || '',
      gym: data.gym || '',
      pool: data.pool || '',
      parking: data.parking || '',
      freeWifi: data.freeWifi || '',
      breakfastBuffet: data.breakfastBuffet || '',
      expressCheckout: data.expressCheckout || '',
    };
    this._container = ''
    this._guestRoomPlaceHolder = config.guestRoomPlaceHolder ? config.guestRoomPlaceHolder : HotelCapacity.DEFAULT_GUEST_ROOMS;
    this._meetingRoomsPlaceHolder = config.meetingRoomsPlaceHolder ? config.meetingRoomsPlaceHolder : HotelCapacity.DEFAULT_MEETING_ROOMS;
    this._maxCapPlaceHolder = config.maxCapPlaceHolder ? config.maxCapPlaceHolder : HotelCapacity.DEFAULT_MAX_CAP;
    this._exhibitSpacePlaceHolder = config.exhibitSpacePlaceHolder ? config.exhibitSpacePlaceHolder : HotelCapacity.DEFAULT_EXHIBIT_SPACE;
    this._guestRooms = this.getGuestRooms();
    this._meetingRooms = this.getMeetingRooms();
    this._maxCap = this.getMaxCap();
    this._exhibitSpace = this.getExhibitSpace();
    this._suites = this.getNewInput('suites');
    this._familyRooms = this.getNewInput('familyRooms');
    this._restaurants = this.getNewInput('restaurants');
    this._bars = this.getNewInput('bars');
    this._spa = this.getNewInput('spa');
    this._gym = this.getNewInput('gym');
    this._pool = this.getNewInput('pool');
    this._parking = this.getNewInput('parking');
    this._freeWifi = this.getNewInput('freeWifi');
    this._breakfastBuffet = this.getNewInput('breakfastBuffet');
    this._expressCheckout = this.getNewInput('expressCheckout');
    if (!this.readOnly) {
      this._onKeyUp = this.onKeyUp.bind(this);
      this._onKeyDown = this.onKeyDown.bind(this);
    }
  }

  getGuestRooms() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._guestRoomPlaceHolder);
    tag.innerHTML = this._data.guestRoom || '';
    tag.classList.add(this._CSS.guestRoom, this._CSS.input, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._guestRooms);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getMeetingRooms() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._meetingRoomsPlaceHolder);
    tag.innerHTML = this._data.meetingRooms || '';
    tag.classList.add(this._CSS.meetingRooms, this._CSS.input, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._meetingRooms);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getMaxCap() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._maxCapPlaceHolder);
    tag.innerHTML = this._data.maxCap || '';
    tag.classList.add(this._CSS.maxCap, this._CSS.input, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._maxCap);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getExhibitSpace() {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._exhibitSpacePlaceHolder);
    tag.innerHTML = this._data.exhibitSpace || '';
    tag.classList.add(this._CSS.exhibitSpace, this._CSS.input, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, this._exhibitSpace);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }

  getNewInput(name) {
    let tag = document.createElement('h6');

    tag.dataset.placeholder = this.api.i18n.t(this._maxCapPlaceHolder);
    tag.innerHTML = this._data[name] || '';
    tag.classList.add(this._CSS[name], this._CSS.input, this._CSS.customInput);
    tag.contentEditable = this.readOnly ? 'false' : 'true';
    if (!this.readOnly) {
      tag.addEventListener('keyup', (e) => {
        this._onKeyUp(e, tag);
      });
      tag.addEventListener('keydown', (e) => {
        this._onKeyDown(e, tag);
      });
    }

    return tag
  }


  onKeyUp(e, element) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = element;

    if (textContent === '') {
      element = '';
    }
  }

  onKeyDown(e, element) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.addLineBreak(element);
    }
  }

  render() {
    const makeLabel = (label, labelClass) => {
      const tag = document.createElement('div');
      tag.classList.add(this._CSS.label, `hotel-capacity-${labelClass}-label`);
      tag.innerHTML = label;
      return tag
    }

    const groupAndAppend = (input, label, groupName) => {
      const groupTag = document.createElement('div');
      groupTag.classList.add('hotel-capacity-group', `${groupName}-group`);
      groupTag.appendChild(input);
      groupTag.appendChild(label);
      contentContainer.appendChild(groupTag);
    }

    this._container = document.createElement('div');
    this._container.classList.add(this._CSS.container);
    const guestRoomsLabel = makeLabel('GUEST<br>ROOMS', 'guest-rooms');
    const meetingRoomsLabel = makeLabel('MEETING<br>ROOMS', 'meeting-rooms');
    const maxCapLabel = makeLabel('MAX<br>CAP', 'max-cap');
    const exhibitSpaceLabel = makeLabel('EXHIBITION<br>SPACE SQM', 'exhibit-space');
    const suitesLabel = makeLabel('SUITES', 'suites');
    const familyRoomsLabel = makeLabel('FAMILY<br>ROOMS', 'family-rooms');
    const restaurantsLabel = makeLabel('RESTAURANTS', 'restaurants');
    const barsLabel = makeLabel('BARS', 'bars');
    const spaLabel = makeLabel('SPA', 'spa');
    const gymLabel = makeLabel('GYM', 'gym');
    const poolLabel = makeLabel('POOL', 'pool');
    const parkingLabel = makeLabel('PARKING', 'parking');
    const freeWifiLabel = makeLabel('FREE<br>WIFI', 'free-wifi');
    const breakfastBuffetLabel = makeLabel('BREAKFAST<br>BUFFET', 'breakfast-buffet');
    const expressCheckoutLabel = makeLabel('EXPRESS<br>CHECK-OUT', 'express-checkout');

    let contentContainer = document.createElement('div');
    contentContainer.classList.add(this._CSS.contentContainer);


    groupAndAppend(this._guestRooms, guestRoomsLabel, 'guest-rooms');
    groupAndAppend(this._meetingRooms, meetingRoomsLabel, 'meeting-rooms');
    groupAndAppend(this._maxCap, maxCapLabel, 'max-cap');
    groupAndAppend(this._exhibitSpace, exhibitSpaceLabel, 'exhibit-space');
    groupAndAppend(this._suites, suitesLabel, 'suites');
    groupAndAppend(this._familyRooms, familyRoomsLabel, 'family-rooms');
    groupAndAppend(this._restaurants, restaurantsLabel, 'restaurants');
    groupAndAppend(this._bars, barsLabel, 'bars');
    groupAndAppend(this._spa, spaLabel, 'spa');
    groupAndAppend(this._gym, gymLabel, 'gym');
    groupAndAppend(this._pool, poolLabel, 'pool');
    groupAndAppend(this._parking, parkingLabel, 'parking');
    groupAndAppend(this._freeWifi, freeWifiLabel, 'free-wifi');
    groupAndAppend(this._breakfastBuffet, breakfastBuffetLabel, 'breakfast-buffet');
    groupAndAppend(this._expressCheckout, expressCheckoutLabel, 'express-checkout');

    this._container.appendChild(contentContainer);

    return this._container;
  }

  save() {
    this._data.guestRoom = this._guestRooms.innerHTML;
    this._data.meetingRooms = this._meetingRooms.innerHTML;
    this._data.maxCap = this._maxCap.innerHTML;
    this._data.exhibitSpace = this._exhibitSpace.innerHTML;
    this._data.suites = this._suites.innerHTML;
    this._data.familyRooms = this._familyRooms.innerHTML;
    this._data.restaurants = this._restaurants.innerHTML;
    this._data.bars = this._bars.innerHTML;
    this._data.spa = this._spa.innerHTML;
    this._data.gym = this._gym.innerHTML;
    this._data.pool = this._pool.innerHTML;
    this._data.parking = this._parking.innerHTML;
    this._data.freeWifi = this._freeWifi.innerHTML;
    this._data.breakfastBuffet = this._breakfastBuffet.innerHTML;
    this._data.expressCheckout = this._expressCheckout.innerHTML;

    return this._data;
  }
}