import { createTheme, alpha } from '@mui/material/styles';
import ClientTheme from '../core/clientTheme'
import { hexToRgbA } from '../utils/helpers';

// Usage
const rgbaColor = hexToRgbA('#000000', 0.2);
const adminTheme = ClientTheme(true);

export const companyappTheme = () => {
  let theme = createTheme({
    palette: {
      primary: {
        main: '#1d9bf1',
        light: '#edf7fe',
        dark: '#164a8d',
        contrastText: '#fff',
        confirm: '#20bf55',
      },
      secondary: {
        main: '#20bf55',
        light: '#edfaf1',
        dark: '#188c3e',
        contrastText: '#fff',
      },
      error: {
        main: '#bf1e2c',
        light: '#fcedef',
        dark: '#8d1620',
        contrastText: '#fff',
      },
      solid: {
        light: '#fff',
        main: '#777',
        dark: '#121212',
        contrastText: 'rgba(0, 0, 0, 0.87)',
        confirm: '#20bf55',
      },
      white: {
        light: '#fff',
        main: '#fff',
        dark: '#fff',
        contrastText: 'rgba(0, 0, 0, 0.87)',
        confirm: '#fff',
      },
      modal: {
        main: '#d4ecff',
        light: '#415262',
        dark: '#16324f',
        contrastText: '#fff',
      },
      rhg: {
        main: '#C00D0D',
        light: '#EF1A1A',
        dark: '#860909',
        contrastText: '#fff',
      }
    },
  });

  const clientTheme = { ...ClientTheme() };

  theme = createTheme(theme, {
    palette: {
      // CLIENT PALETTE
      clientPrimary: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.brand.primary
          // main: '#f9690e'
        },
        name: 'clientPrimary'
      }),
      clientSecondary: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.brand?.secondary ?? clientTheme.color.brand.primary
        },
        name: 'clientSecondary'
      }),
      clientText: { ...clientTheme.color.text },
      clientBackground: { ...clientTheme.color.background },
      clientHeading: {
        sideBar: clientTheme.color.heading?.sideBar ?? clientTheme.color.text.main,
        items: clientTheme.color.heading?.items ?? clientTheme.color.text.dark,
        eventMonth: clientTheme.color.heading?.eventMonth ?? clientTheme.color.brand.primary,
        trainingSection: clientTheme.color.heading?.trainingSection ?? clientTheme.color.brand.primary,
      },
      clientHeadingScreens: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.heading?.screens ?? clientTheme.color.text.dark
        },
        name: 'clientHeadingScreens'
      }),
      clientCategory: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.heading?.screens ?? clientTheme.color.text.dark
        },
        name: 'clientCategory'
      }),
      clientDate: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.metaData?.date ?? clientTheme.color.text.main,
        },
        name: 'clientDate'
      }),
      commentLike: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.reactions.like ?? "#1e90ff",
        },
        name: 'commentLike'
      }),
      commentLove: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.reactions.love ?? "#dc143c",
        },
        name: 'commentLove'
      }),
      commentLaugh: theme.palette.augmentColor({
        color: {
          main: clientTheme.color.reactions.laugh ?? "#ffa500",
        },
        name: 'commentLaugh'
      }),
      // ADMIN PALETTE
      adminPrimary: theme.palette.augmentColor({
        color: {
          main: '#1d9bf1',
          // main: '#4caf50',
          contrastText: '#ffffff',
        },
        name: 'adminPrimary'
      }),
      adminText: {
        main: '#415262',
        dark: '#16324f',
        light: '#edf7fe',
        contrastText: '#ffffff',
        disable: '#c2ccd6',
      }
    },
  })

  const clientFontFamily = clientTheme.font.family.primary;
  const clientSecondaryFontFamily = clientTheme.font.family?.secondary ?? clientTheme.font.family.primary;
  const clientLetterSpacing = clientTheme.font.letterSpacing;
  const clientHeadingFontWeight = clientTheme.font.headingFontWeight;
  const clientBaseLineHeight = clientTheme.font.baseLineHeight;
  const clientFontSizes = { ...clientTheme.font.sizes };

  const adminFontFamily = 'Roboto'
  const adminLetterSpacing = 'normal'
  const adminHeadingFontWeight = 500;
  const adminBaseLineHeight = '1rem'
  const adminFontSizes = {
    h1: '2rem',
    h2: '1.8rem',
    h3: '1.5rem',
    h4: '1.3rem',
    h5: '1.2rem',
    h6: '1rem',
    subtitle1: '0.9rem',
    subtitle2: '0.8rem',
    body1: '1rem',
    body2: '0.8rem',
    button: '0.7rem',
    caption: '0.6rem',
    overline: '0.7rem',
  }

  theme = createTheme(theme, {
    components: {
      MuiFormControlLabel: {
        variants: [
          {
            props: {
              variant: 'nav-item'
            },
            style: {
              display: 'flex',
              alignItems: 'center',
              '& .MuiIconButton-root': {
                marginRight: '1rem',
                marginLeft: '10px',
                // color: theme.palette.clientPrimary.main
                color: clientTheme.color.sideBar.default.icon ?? theme.palette.clientText.main,
                transition: 'all .5s ease'
              },
              '& .MuiTypography-span': {
                color: clientTheme.color.sideBar.default.text ?? theme.palette.clientText.main,
                transition: 'all .5s ease'
              },
              '&:hover, &[color="active"]': {
                '& .MuiIconButton-root': {
                  color: clientTheme.color.sideBar.active.icon ?? theme.palette.clientPrimary.main,
                  backgroundColor: alpha(clientTheme.color.sideBar.active.icon ?? theme.palette.clientPrimary.main, 0.2),
                },
                '& .MuiTypography-span': {
                  color: clientTheme.color.sideBar.active.text ?? theme.palette.clientPrimary.main,
                }
              },
            }
          },
          {
            props: {
              variant: 'nav-item',
              color: 'active'
            },
            style: {
              '& .MuiIconButton-root': {
                color: theme.palette.clientPrimary.main,
                backgroundColor: alpha(theme.palette.clientPrimary.main, 0.1),
              },
              '& .MuiTypography-span': {
                color: theme.palette.clientPrimary.main,
              }
            }
          }
        ]
      },
      MuiTypography: {
        variants: [

          // Client
          {
            props: {
              owner: 'client'
            },
            style: {
              fontFamily: clientFontFamily,
              fontSize: 'unset',
              '&.isHovered, &.isActive': {
                color: `${theme.palette.clientPrimary.main} !important`
              },
              letterSpacing: clientLetterSpacing
            }
          },

          // Typography font size overrides
          {
            props: {
              owner: 'client',
              variant: 'h1'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.h1,
              lineHeight: clientFontSizes.h1,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h2'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.h2,
              lineHeight: clientFontSizes.h2,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h3'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.h3,
              lineHeight: clientFontSizes.h3,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h4'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.h4,
              lineHeight: clientFontSizes.h4,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h5'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.h5,
              lineHeight: clientFontSizes.h5,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h6'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.h6,
              lineHeight: clientFontSizes.h6,
            }
          }, ,
          {
            props: {
              owner: 'client',
              variant: 'subtitle1'
            },
            style: {
              fontSize: clientFontSizes.subtitle1,
              lineHeight: clientFontSizes.subtitle1,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'subtitle2'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.subtitle2,
              lineHeight: clientFontSizes.subtitle2,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'body1'
            },
            style: {
              fontSize: clientFontSizes.body1,
              lineHeight: clientFontSizes.body1,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'body2'
            },
            style: {
              fontSize: clientFontSizes.body2,
              lineHeight: clientFontSizes.body2,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'button'
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: clientFontSizes.button,
              lineHeight: clientFontSizes.button,
              textTransform: 'none',
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'caption'
            },
            style: {
              fontSize: clientFontSizes.caption,
              lineHeight: clientFontSizes.caption,
              "&.label, &.value": {
                fontSize: `calc(${clientFontSizes.caption} - 0.025rem)`,
              }
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'overline'
            },
            style: {
              fontSize: clientFontSizes.overline,
              lineHeight: clientFontSizes.overline,
            }
          },

          // Typography font size overrides for singles page/s
          {
            props: {
              owner: 'client',
              variant: 'h1',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.h1} + 0.2rem)`,
              lineHeight: clientFontSizes.h1,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h2',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.h2} + 0.2rem)`,
              lineHeight: clientFontSizes.h2,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h3',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.h3} + 0.2rem)`,
              lineHeight: clientFontSizes.h3,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h4',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.h4} + 0.2rem)`,
              lineHeight: clientFontSizes.h4,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h5',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.h5} + 0.2rem)`,
              lineHeight: clientFontSizes.h5,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'h6',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.h6} + 0.2rem)`,
              lineHeight: clientFontSizes.h6,
            }
          }, ,
          {
            props: {
              owner: 'client',
              variant: 'subtitle1',
              singles: "true"
            },
            style: {
              fontSize: `calc(${clientFontSizes.subtitle1} + 0.2rem)`,
              lineHeight: clientFontSizes.subtitle1,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'subtitle2',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.subtitle2} + 0.2rem)`,
              lineHeight: clientFontSizes.subtitle2,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'body1',
              singles: "true"
            },
            style: {
              fontSize: `calc(${clientFontSizes.body1} + 0.2rem)`,
              lineHeight: clientFontSizes.body1,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'body2',
              singles: "true"
            },
            style: {
              fontSize: `calc(${clientFontSizes.body2} + 0.2rem)`,
              lineHeight: clientFontSizes.body2,
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'button',
              singles: "true"
            },
            style: {
              fontWeight: clientHeadingFontWeight,
              fontSize: `calc(${clientFontSizes.button} + 0.2rem)`,
              lineHeight: clientFontSizes.button,
              textTransform: 'none',
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'caption',
              singles: "true"
            },
            style: {
              fontSize: `calc(${clientFontSizes.caption} + 0.2rem)`,
              lineHeight: clientFontSizes.caption,
              "&.label, &.value": {
                fontSize: `calc(${clientFontSizes.caption} - 0.025rem)`,
              }
            }
          },
          {
            props: {
              owner: 'client',
              variant: 'overline',
              singles: "true"
            },
            style: {
              fontSize: `calc(${clientFontSizes.overline} + 0.2rem)`,
              lineHeight: clientFontSizes.overline,
            }
          },


          // Typography line-height overrides
          {
            props: {
              owner: 'client',
              lh: 'base'
            },
            style: {
              lineHeight: clientBaseLineHeight
            }
          },
          {
            props: {
              owner: 'client',
              lh: 'narrow'
            },
            style: {
              lineHeight: `calc(${clientBaseLineHeight} * 0.8)`
            }
          },
          {
            props: {
              owner: 'client',
              lh: 'medium'
            },
            style: {
              lineHeight: `calc(${clientBaseLineHeight} * 1.2)`
            }
          },
          {
            props: {
              owner: 'client',
              lh: 'wide'
            },
            style: {
              lineHeight: `calc(${clientBaseLineHeight} * 1.5)`
            }
          },
          {
            props: {
              owner: 'client',
              lh: 'x-wide'
            },
            style: {
              lineHeight: `calc(${clientBaseLineHeight} * 1.75)`
            }
          },
          {
            props: {
              owner: 'client',
              lh: 'double'
            },
            style: {
              lineHeight: `calc(${clientBaseLineHeight} * 2)`
            }
          },


          // Admin
          {
            props: {
              owner: 'admin'
            },
            style: {
              fontFamily: adminFontFamily,
              fontSize: 'unset',
              '&.isHovered, &.isActive': {
                color: `${theme.palette.adminPrimary.main} !important`
              },
              letterSpacing: adminLetterSpacing
            }
          },


          // Typography font size overrides
          {
            props: {
              owner: 'admin',
              variant: 'h1'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.h1,
              lineHeight: adminFontSizes.h1,
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'h2'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.h2,
              lineHeight: adminFontSizes.h2,
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'h3'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.h3,
              lineHeight: adminFontSizes.h3,
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'h4'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.h4,
              lineHeight: adminFontSizes.h4,
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'h5'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.h5,
              lineHeight: adminFontSizes.h5,
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'h6'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.h6,
              lineHeight: adminFontSizes.h6,
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'subtitle1'
            },
            style: {
              fontSize: adminFontSizes.subtitle1,
              lineHeight: adminFontSizes.subtitle1,
              "&.lh": {
                lineHeight: `calc(${adminFontSizes.subtitle2} * 1.375)`
              }
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'subtitle2'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.subtitle2,
              lineHeight: adminFontSizes.subtitle2,
              "&.lh": {
                lineHeight: `calc(${adminFontSizes.subtitle2} * 1.375)`
              },
              "&.btn-lh": {
                lineHeight: `calc(${adminFontSizes.subtitle2} * 1.8)`
              }
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'body1'
            },
            style: {
              fontSize: adminFontSizes.body1,
              lineHeight: adminFontSizes.body1,
              "&.lh": {
                lineHeight: `calc(${adminFontSizes.body1} * 1.375)`
              }
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'body2'
            },
            style: {
              fontSize: adminFontSizes.body2,
              lineHeight: adminFontSizes.body2,
              "&.lh": {
                lineHeight: `calc(${adminFontSizes.body2} * 1.375)`
              }
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'button'
            },
            style: {
              fontWeight: adminHeadingFontWeight,
              fontSize: adminFontSizes.button,
              lineHeight: adminFontSizes.button,
              textTransform: 'none',
              "&.lh": {
                lineHeight: `calc(${adminFontSizes.button} * 1.8)`
              }
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'caption'
            },
            style: {
              fontSize: adminFontSizes.caption,
              lineHeight: adminFontSizes.caption,
              "&.label, &.value": {
                fontSize: `calc(${adminFontSizes.caption} - 0.025rem)`,
              },
              "&.lh": {
                lineHeight: `calc(${adminFontSizes.caption} * 1.375)`
              }
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'overline'
            },
            style: {
              fontSize: adminFontSizes.overline,
              lineHeight: adminFontSizes.overline,
            }
          },
          // Typography line-height overrides
          {
            props: {
              owner: 'admin',
              lh: 'base'
            },
            style: {
              lineHeight: adminBaseLineHeight
            }
          },
          {
            props: {
              owner: 'admin',
              lh: 'narrow'
            },
            style: {
              lineHeight: `calc(${adminBaseLineHeight} * 0.8)`
            }
          },
          {
            props: {
              owner: 'admin',
              lh: 'medium'
            },
            style: {
              lineHeight: `calc(${adminBaseLineHeight} * 1.2)`
            }
          },
          {
            props: {
              owner: 'admin',
              lh: 'wide'
            },
            style: {
              lineHeight: `calc(${adminBaseLineHeight} * 1.5)`
            }
          },
          {
            props: {
              owner: 'admin',
              lh: 'x-wide'
            },
            style: {
              lineHeight: `calc(${adminBaseLineHeight} * 1.75)`
            }
          },
          {
            props: {
              owner: 'admin',
              lh: 'double'
            },
            style: {
              lineHeight: `calc(${adminBaseLineHeight} * 2)`
            }
          },

          {
            props: {
              break: 'break-all'
            },
            style: {
              wordBreak: 'break-all'
            }
          },


          // Component-specific, styled overrides
          {
            props: {
              owner: 'admin',
              variant: 'form-group-label'
            },
            style: {
              fontWeight: `${adminHeadingFontWeight}`,
              color: theme.palette.adminPrimary.main,
              fontSize: '0.8rem',
              '&.vertical': {
                marginBottom: '5px'
              }
            }
          },

          // GENERAL FONT WEIGHT OVERRIDES
          {
            props: {
              weight: 'light',
            },
            style: {
              fontWeight: 300,
            }
          },
          {
            props: {
              weight: 'regular',
            },
            style: {
              fontWeight: 400,
            }
          },
          {
            props: {
              weight: 'medium',
            },
            style: {
              fontWeight: 500,
            }
          },
          {
            props: {
              weight: 'semiBold',
            },
            style: {
              fontWeight: 600,
            }
          },
          {
            props: {
              weight: 'bold',
            },
            style: {
              fontWeight: 700,
            }
          },
          {
            props: {
              weight: 'extraBold',
            },
            style: {
              fontWeight: 800,
            }
          },

          // TEXT-TRANSFORM OVERRIDES

          {
            props: {
              uppercase: true
            },
            style: {
              textTransform: 'uppercase'
            }
          },
          {
            props: {
              lowercase: true
            },
            style: {
              textTransform: 'lowercase'
            }
          },
          {
            props: {
              capitalize: true
            },
            style: {
              textTransform: 'capitalize'
            }
          },
          {
            props: {
              notransform: true
            },
            style: {
              textTransform: 'none'
            }
          },

          // FONT FAMILY OVERRIDES

          {
            props: {
              owner: 'client',
              family: 'secondary'
            },
            style: {
              fontFamily: clientSecondaryFontFamily
            }
          },
        ]
      },
      MuiCard: {
        variants: [
          {
            props: {
              variant: 'presentation-item',
              owner: 'client'
            },
            style: {
              '&.isHovered': {
                borderColor: `${theme.palette.clientPrimary.main} !important`
              }
            }
          },
          {
            props: {
              variant: 'presentation-item',
              owner: 'admin'
            },
            style: {
              '&.isHovered': {
                borderColor: `${theme.palette.adminPrimary.main} !important`
              }
            }
          }
        ]
      },
      MuiIconButton: {
        variants: [
          {
            props: {
              variant: 'circular',
              owner: 'admin'
            },
            style: {
              backgroundColor: theme.palette.adminPrimary.main,
              '&:hover': {
                backgroundColor: theme.palette.adminPrimary.dark
              }
            }
          }
        ]
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          }
        },
        variants: [
          {
            props: {
              variant: 'circular',
              owner: 'admin'
            },
            style: {
              textTransform: 'none',
              fontSize: '16px',
              color: theme.palette.adminText.dark,
              '& .MuiSvgIcon-root': {
                backgroundColor: theme.palette.adminPrimary.main,
                padding: '4px',
                borderRadius: '50%',
                fontSize: '26px',
                color: theme.palette.adminText.contrastText,
              },
              '&:hover': {
                backgroundColor: 'transparent',
                '& .MuiSvgIcon-root': {
                  backgroundColor: theme.palette.adminPrimary.dark,
                }
              }
            }
          },
          {
            props: {
              variant: 'toolbar-btn',
              owner: 'admin'
            },
            style: {
              fontSize: 16,
              fontWeight: adminHeadingFontWeight,
              color: theme.palette.adminText.dark,
              padding: '0 8px 0 8px',
              '&:hover': {
                color: theme.palette.adminText.contrastText,
              }
            }
          },
          {
            props: {
              variant: 'toolbar-btn',
              sub_variant: 'primary',
              owner: 'admin'
            },
            style: {
              '&:hover': {
                backgroundColor: `${theme.palette.adminPrimary.main} !important`,
              }
            }
          },
          {
            props: {
              variant: 'toolbar-btn',
              sub_variant: 'error',
              owner: 'admin'
            },
            style: {
              '&:hover': {
                backgroundColor: theme.palette.error.main,
              }
            }
          },
          {
            props: {
              owner: 'admin',
              variant: 'outlined'
            },
            style: {
              backgroundColor: theme.palette.adminPrimary.contrastText
            }
          },
          {
            props: {
              variant: 'outlined',
              owner: 'admin',
              dialogbtn: "true",
              dialogvariant: 'cancel'
            },
            style: {
              backgroundColor: theme.palette.adminPrimary.contrastText,
              borderColor: `rgba(${theme.palette.adminPrimary.main}, 0.5)`,
              minWidth: '100px',
              marginRight: '10px',
              '&:hover': {
                backgroundColor: theme.palette.adminPrimary.contrastText,
                borderColor: `rgba(${theme.palette.adminPrimary.main}, 1)`,
              }
            }
          },
          {
            props: {
              variant: 'contained',
              owner: 'admin',
              dialogbtn: "true",
              dialogvariant: 'save'
            },
            style: {
              backgroundColor: theme.palette.secondary.main,
              borderColor: theme.palette.secondary.main,
              minWidth: '100px',
              marginRight: '10px',
              marginLeft: '8px',
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark,
              }
            }
          },
          {
            props: {
              variant: 'contained',
              owner: 'admin',
              dialogbtn: "true",
              dialogvariant: 'error'
            },
            style: {
              backgroundColor: theme.palette.error.main,
              borderColor: theme.palette.error.main,
              minWidth: '100px',
              marginRight: '10px',
              marginLeft: '8px',
              '&:hover': {
                backgroundColor: theme.palette.error.dark,
                borderColor: theme.palette.error.dark,
              }
            }
          },
          {
            props: {
              variant: 'contained',
              owner: 'admin',
              dialogbtn: "true",
              dialogvariant: 'primary'
            },
            style: {
              backgroundColor: theme.palette.adminPrimary.main,
              borderColor: theme.palette.adminPrimary.main,
              minWidth: '100px',
              marginRight: '10px',
              marginLeft: '8px',
              '&:hover': {
                backgroundColor: theme.palette.adminPrimary.dark,
                borderColor: theme.palette.adminPrimary.dark,
              }
            }
          },
          {
            props: {
              variant: 'screenItemBtn',
              mode: 'default'
            },
            style: {
              backgroundColor: clientTheme.color.metaData.itemBtn.default.background ?? "transparent",
              color: clientTheme.color.metaData.itemBtn.default.color ?? theme.palette.clientPrimary.main,
              border: clientTheme.color.metaData.itemBtn.default.border ? `1px solid ${clientTheme.color.metaData.itemBtn.default.border}` : "none",
              "&:hover": {
                backgroundColor: clientTheme.color.metaData.itemBtn.default.hover.background ?? hexToRgbA(theme.palette.clientPrimary.main, 0.04),
                color: clientTheme.color.metaData.itemBtn.default.hover.color ?? theme.palette.clientPrimary.main,
                border: clientTheme.color.metaData.itemBtn.default.hover.border ? `1px solid ${clientTheme.color.metaData.itemBtn.default.hover.border}` : "none"
              },
              borderRadius: "50px",
              padding: "5px",
              minWidth: "unset",
              marginLeft: "0px",
              marginRight: "0rem",
            }
          },
          {
            props: {
              variant: 'screenItemBtn',
              mode: 'featured'
            },
            style: {
              backgroundColor: clientTheme.color.metaData.itemBtn.featured.background ?? "#f3f5f7",
              color: clientTheme.color.metaData.itemBtn.featured.color ?? theme.palette.clientPrimary.main,
              border: clientTheme.color.metaData.itemBtn.featured.border ? `1px solid ${clientTheme.color.metaData.itemBtn.featured.border}` : "none",
              "& svg": {
                filter: clientTheme.color.metaData.itemBtn.featured.background === "transparent" ? "drop-shadow(1px 1px 1px #000)" : "none"
              },
              "&:hover": {
                backgroundColor: clientTheme.color.metaData.itemBtn.featured.hover.background ?? hexToRgbA("#f3f5f7", 0.9),
                color: clientTheme.color.metaData.itemBtn.featured.hover.color ?? theme.palette.clientPrimary.main,
                border: clientTheme.color.metaData.itemBtn.featured.hover.border ? `1px solid ${clientTheme.color.metaData.itemBtn.featured.hover.border}` : "none"
              },
              borderRadius: "50px",
              padding: "2px",
              minWidth: "unset",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
            }
          },
          {
            props: {
              variant: 'listItemBtn',
              mode: 'default',
              owner: 'client'
            },
            style: {
              backgroundColor: clientTheme.color.metaData.itemBtn.default.background ?? "transparent",
              color: clientTheme.color.metaData.itemBtn.default.color ?? theme.palette.clientPrimary.main,
              border: clientTheme.color.metaData.itemBtn.default.border ? `1px solid ${clientTheme.color.metaData.itemBtn.default.border}` : "none",
              "&:hover": {
                backgroundColor: clientTheme.color.metaData.itemBtn.default.hover.background ?? hexToRgbA(theme.palette.clientPrimary.main, 0.04),
                color: clientTheme.color.metaData.itemBtn.default.hover.color ?? theme.palette.clientPrimary.main,
                border: clientTheme.color.metaData.itemBtn.default.hover.border ? `1px solid ${clientTheme.color.metaData.itemBtn.default.hover.border}` : "none"
              },
              borderRadius: "50px",
              padding: "5px",
              minWidth: "unset",
              marginLeft: "0px",
              marginRight: "0rem",
            }
          },
          {
            props: {
              variant: 'listItemBtn',
              owner: 'admin',
              mode: 'default'
            },
            style: {
              backgroundColor: "transparent",
              color: theme.palette.adminPrimary.main,
              border: "none",
              "&:hover": {
                backgroundColor: hexToRgbA(theme.palette.adminPrimary.main, 0.04),
                color: theme.palette.adminPrimary.main,
                border: "none"
              },
              borderRadius: "50px",
              padding: "5px",
              minWidth: "unset",
              marginLeft: "0px",
              marginRight: "0rem",
            }
          },
          {
            props: {
              variant: "trainingItemBtn"
            },
            style: {
              "& .MuiTypography-root": {
                color: clientTheme.color.trainingItemBtn.text ?? theme.palette.adminPrimary.main
              },
              "& .MuiSvgIcon-root": {
                fill: clientTheme.color.trainingItemBtn.icon ?? theme.palette.adminPrimary.main
              }
            }
          },
          {
            props: {
              variant: "postSubmitBtn"
            },
            style: {
              backgroundColor: clientTheme.color.postSubmitBtn.background ?? theme.palette.clientPrimary.main,
              border: clientTheme.color.postSubmitBtn.border ? `1px solid ${clientTheme.color.postSubmitBtn.border}` : "none",
              "& .MuiTypography-root": {
                color: clientTheme.color.postSubmitBtn.text ?? (clientTheme.color.postSubmitBtn.background ? theme.palette.clientText.contrastText : theme.palette.clientText.main)
              }
            }
          },
          {
            props: {
              variant: 'searchItem',
              owner: 'client'
            },
            style: {
              color: clientTheme.color.searchItem.color ?? theme.palette.clientText.main,
              border: clientTheme.color.searchItem.border ? `2px solid ${clientTheme.color.searchItem.border}` : 'none',
              background: clientTheme.color.searchItem.background ?? 'transparent',
              "&:hover": {
                color: clientTheme.color.searchItem.hover.color ?? theme.palette.clientPrimary.main,
                border: clientTheme.color.searchItem.hover.border ? `2px solid ${clientTheme.color.searchItem.hover.border}` : 'none',
                background: clientTheme.color.searchItem.hover.background ?? 'transparent',
              }
            }
          }
        ]
      },
      MuiTextField: {
        variants: [
          {
            props: {
              owner: 'admin',
              sub_variant: 'presentation-filter'
            },
            style: {
              '& label[data-shrink="true"]': {
                color: theme.palette.primary.main,
              },
              '& label[data-shrink="false"]': {
                color: 'rgba(0, 0, 0, 0.87)',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.primary.main,
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: theme.palette.primary.light,
                paddingRight: '10px',
                '& fieldset': {
                  borderColor: '#c2ccd6',
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                  borderWidth: '2px'
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }
          },
          {
            props: {
              sub_variant: "search",
              owner: "client"
            },
            style: {
              "& .MuiInputBase-root": {
                paddingRight: '0',
              },
              "& .MuiInputBase-input": {
                fontSize: "0.8rem",
                padding: "3px 5px",
                color: clientTheme.color.searchBox.color ?? theme.palette.clientText.main,
                backgroundColor: clientTheme.color.searchBox.background ?? "transparent",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: clientTheme.color.searchBox.border ?? "rgba(0, 0, 0, 0.23)",
              },

              "&:hover": {
                "& .MuiInputBase-input": {
                  color: clientTheme.color.searchBox.hover.color ?? theme.palette.clientText.main,
                  backgroundColor: clientTheme.color.searchBox.hover.background ?? "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: clientTheme.color.searchBox.hover.border ? `${clientTheme.color.searchBox.hover.border} !important` : "rgba(0, 0, 0, 0.87)",
                }
              },

              "& .Mui-focused": {
                "& .MuiInputBase-input": {
                  color: clientTheme.color.searchBox.focus.color ?? theme.palette.clientText.main,
                  backgroundColor: clientTheme.color.searchBox.focus.background ?? "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: clientTheme.color.searchBox.focus.border ? `${clientTheme.color.searchBox.focus.border} !important` : `${theme.palette.clientPrimary.main} !important`,
                }
              }
            }
          }
        ]
      },
      MuiOutlinedInput: {
        variants: [
          {
            props: {
              owner: 'admin',
              sub_variant: 'presentation-filter',
            },
            style: {
              textTransform: 'capitalize',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#c2ccd6'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                borderColor: theme.palette.primary.main
              },
            }
          }
        ]
      },
      MuiLink: {
        variants: [
          {
            props: {
              hoverbehavior: "default"
            },
            style: {
              color: clientTheme.color.link.main ?? theme.palette.clientPrimary.main,
              textDecoration: "none",
              textDecorationColor: "transparent",
              '&:hover': {
                color: clientTheme.color.link.hover ?? theme.palette.clientPrimary.main,
                textDecoration: clientTheme.color.link.decoration ?? "underline",
                textDecorationColor: clientTheme.color.link.decorationColor ?? theme.palette.clientPrimary.main
              }
            }
          },
          {
            props: {
              hoverbehavior: "reverse"
            },
            style: {
              color: clientTheme.color.link.main ?? theme.palette.clientPrimary.main,
              textDecoration: clientTheme.color.link.decoration ?? "underline",
              textDecorationColor: clientTheme.color.link.decorationColor ?? theme.palette.clientPrimary.main,
              '&:hover': {
                color: clientTheme.color.link.hover ?? theme.palette.clientPrimary.main,
                textDecoration: "none",
                textDecorationColor: "transparent",
              }
            }
          },
        ]
      },
      MuiChip: {
        variants: [
          {
            props: {
              variant: 'default',
              owner: 'client',
              role: 'metaData'
            },
            style: {
              border: `1px solid ${clientTheme.color.chip.default.border ?? 'transparent'}`,
              backgroundColor: clientTheme.color.chip.default.background ?? '#f3f5f7',
              "& .MuiChip-label": {
                display: "flex",
                alignItems: "center"
              },
              '& .MuiTypography-root': {
                color: clientTheme.color.chip.default.text ?? clientTheme.color.text.main,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              },
              '&': clientTheme.color.chip.default.background === 'transparent' && clientTheme.color.chip.default.border === "transparent" ? {
                paddingLeft: "0 !important",
                "& .MuiChip-label": {
                  paddingLeft: 0
                }
              } : {}
            }
          },
          {
            props: {
              variant: 'featured',
              owner: 'client',
              role: 'metaData'
            },
            style: {
              border: `1px solid ${clientTheme.color.chip.featured.border ?? 'transparent'}`,
              backgroundColor: clientTheme.color.chip.featured.background ?? '#f3f5f7',
              "& .MuiChip-label": {
                display: "flex",
                alignItems: "center"
              },
              '& .MuiTypography-root': {
                color: clientTheme.color.chip.featured.text ?? clientTheme.color.text.main,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              },
              svg: {
                filter: clientTheme.color.chip.featured.background === 'transparent' ? 'drop-shadow(1px 1px 1px #000)' : 'none',
              },
              textShadow: clientTheme.color.chip.featured.background === 'transparent' ? '1px 1px 1px #000' : 'none'
            }
          },
        ]
      },
      MuiContainer: {
        variants: [
          {
            props: {
              variant: 'eventContainer'
            },
            style: {
              padding: "0 !important",
              "& .grid-item-event-day": {
                backgroundColor: clientTheme.color.event.day.background ?? clientTheme.color.brand.primary,
                borderTopLeftRadius: clientTheme.components.event.day.round ? "5px" : 0,
                borderTop: clientTheme.components.event.day.border ? "1px solid #d8d8d8" : 0,
                borderLeft: clientTheme.components.event.day.border ? "1px solid #d8d8d8" : 0,
                "& .MuiTypography-root": {
                  color: clientTheme.color.event.day.text ?? clientTheme.color.text.main,
                }
              },
              "& .grid-item-event-date": {
                backgroundColor: clientTheme.color.event.date.background ?? "#f3f5f7",
                borderBottomLeftRadius: clientTheme.components.event.date.round ? "5px" : 0,
                borderLeft: clientTheme.components.event.day.border ? "1px solid #d8d8d8" : 0,
                borderBottom: clientTheme.components.event.day.border ? "1px solid #d8d8d8" : 0,
                "& .MuiTypography-root": {
                  color: clientTheme.color.event.date.text ?? clientTheme.color.text.main,
                }
              }
            }
          },
          {
            props: {
              variant: 'screenHeader'
            },
            style: {
              "& .MuiTypography-h1": {
                color: clientTheme.color.screenHeading.title ?? theme.palette.clientText.main
              },
              "& .MuiButtonBase-root": {
                color: clientTheme.color.screenHeading.buttons ?? theme.palette.clientPrimary.main,
                minWidth: 'unset',
                "& .MuiButton-endIcon": {
                  marginLeft: 0,
                  marginRight: 0
                },
                "& .MuiTypography-root": {
                  color: clientTheme.color.screenHeading.buttonText ?? "inherit",
                }
              },
              "& .MuiFormControl-root": {
                "& .MuiFormLabel-root": {
                  color: clientTheme.color.screenHeading.categorySelector.label.default ?? theme.palette.clientText.main,
                  "&.Mui-focused": {
                    color: clientTheme.color.screenHeading.categorySelector.label.focused ?? theme.palette.clientPrimary.main
                  }
                },
                "& .MuiInputBase-root": {
                  "&::before": {
                    borderBottomColor: `${clientTheme.color.screenHeading.categorySelector.underline.default ?? "rgb(210, 208, 207)"} !important`
                  },
                  "&::after": {
                    borderBottomColor: `${clientTheme.color.screenHeading.categorySelector.underline.focused ?? theme.palette.clientPrimary.main} !important`
                  }
                },
                "& .MuiSelect-select": {
                  color: clientTheme.color.screenHeading.categorySelector.text ?? theme.palette.clientText.main
                }
              }
            }
          },
          {
            props: {
              variant: 'screenHeader',
              owner: 'admin'
            },
            style: {
              "& .MuiTypography-h1": {
                color: adminTheme.color.screenHeading.title ?? theme.palette.adminText.main
              },
              "& .MuiButtonBase-root": {
                color: adminTheme.color.screenHeading.buttons ?? theme.palette.adminPrimary.main,
                minWidth: 'unset',
                "& .MuiButton-endIcon": {
                  marginLeft: 0,
                  marginRight: 0
                },
                "& .MuiTypography-root": {
                  color: adminTheme.color.screenHeading.buttonText ?? "inherit",
                }
              },
              "& .MuiFormControl-root": {
                "& .MuiFormLabel-root": {
                  color: adminTheme.color.screenHeading.categorySelector.label.default ?? theme.palette.adminText.main,
                  "&.Mui-focused": {
                    color: adminTheme.color.screenHeading.categorySelector.label.focused ?? theme.palette.adminPrimary.main
                  }
                },
                "& .MuiInputBase-root": {
                  "&::before": {
                    borderBottomColor: `${adminTheme.color.screenHeading.categorySelector.underline.default ?? "rgb(210, 208, 207)"} !important`
                  },
                  "&::after": {
                    borderBottomColor: `${adminTheme.color.screenHeading.categorySelector.underline.focused ?? theme.palette.adminPrimary.main} !important`
                  }
                },
                "& .MuiSelect-select": {
                  color: adminTheme.color.screenHeading.categorySelector.text ?? theme.palette.adminText.main
                }
              }
            }
          }
        ]
      },
      MuiMenuItem: {
        variants: [
          {
            props: {
              variant: 'singleHeader',
            },
            style: {
              fontSize: clientTheme.font.sizes.button,
              color: clientTheme.color.menuItem.text,
              fontWeight: 500
            }
          },
          {
            props: {
              variant: 'singleHeader',
              override: 'true'
            },
            style: {
              "&:hover": {
                backgroundColor: hexToRgbA(clientTheme.color.menuItem.hover ?? clientTheme.color.brand.primary, 0.2)
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: hexToRgbA(clientTheme.color.menuItem.active ?? clientTheme.color.brand.primary, 0.6)
              },
              color: clientTheme.color.menuItem.text,
              fontSize: clientTheme.font.sizes.button,
              fontWeight: 500
            }
          },
          {
            props: {
              variant: "userDropdown"
            },
            style: {
              fontSize: "0.8rem",
              color: theme.palette.adminText.main
            }
          }
        ]
      },
      MuiCheckbox: {
        variants: [
          {
            props: {
              variant: "postCheckBox"
            },
            style: {
              "& .UncheckedIcon": {
                fill: clientTheme.color.postCheckBox.unchecked ?? "#d8d8d8",
                "&:hover": {
                  fill: hexToRgbA((clientTheme.color.postCheckBox.unchecked ?? theme.palette.clientPrimary.main), 0.8)
                }
              },
              "& .CheckedIcon": {
                fill: clientTheme.color.postCheckBox.checked ?? theme.palette.clientPrimary.main
              }
            }
          }
        ]
      },
      MuiPaginationItem: {
        variants: [
          {
            props: {
              owner: 'client'
            },
            style: {
              color: clientTheme.color.pagination.color ?? theme.palette.clientPrimary.contrastText,
              backgroundColor: `${hexToRgbA(clientTheme.color.pagination.background ?? theme.palette.clientPrimary.main, 0.9)} !important`,
              "&:hover": {
                backgroundColor: `${hexToRgbA(clientTheme.color.pagination.background ?? theme.palette.clientPrimary.main, 1)} !important`,
              }
            }
          },
          {
            props: {
              owner: 'admin'
            },
            style: {
              color: theme.palette.adminText.contrastText,
              backgroundColor: `${hexToRgbA(theme.palette.adminPrimary.main, 0.9)} !important`,
              "&:hover": {
                backgroundColor: `${hexToRgbA(theme.palette.adminPrimary.main, 1)} !important`,
              }
            }
          }
        ]
      },
      MuiAvatar: {
        variants:[
          {
            props:{
              owner: 'client',
              sub_variant: 'user-image',
            },
            style:{
              backgroundColor: clientTheme.color.userImage.background,
              borderColor: clientTheme.color.userImage.border,
              fontFamily: clientFontFamily,
              fontWeight: 400,
            }
          }
        ]
      }
    }
  })

  return theme
}