import React, { useState, useRef } from "react";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

// App
import { serverUrl } from "../config";

// UI components
import {
  Alert,
  Dialog,
  DialogContent,
  Box,
  Avatar,
  IconButton,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import {
  PersonOutlined,
  CameraAlt,
  CloudUpload,
  Check,
} from "@mui/icons-material";
import { Container, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UserImage(props) {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [uploadDialog, setDialogModal] = useState(false);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  // Minimum and Maximum resolution
  const MIN_RESOLUTION = { width: 240, height: 240 };
  const MAX_RESOLUTION = { width: 1000, height: 1000 };

  const cropperRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const stringAvatar = (name) => {
    let initials = null;
    let names = name.trim().split(" ");
    if (names.length > 1) {
      initials = `${names[0][0]}${names[1][0]}`;
    } else if (names.length === 1) {
      initials = `${names[0][0]}`;
    } else {
      initials = null;
    }
    return {
      sx: {
        // bgcolor: stringToColor(props.userBrand),
        // border: `1px solid ${stringToColor(props.userBrand)}`,
      },
      children:
        initials !== "" &&
        typeof initials !== "undefined" &&
        initials !== "undefined" ? (
          initials
        ) : (
          <PersonOutlined sx={{ fontSize: "1rem" }} />
        ),
    };
  };

  const stringToColor = (brand) => {
    switch (brand) {
      case "rhg":
        return "#53565A";
      case "blu":
        return "#18448a";
      case "park-inn":
        return "#f5b335";
      case "red":
        return "#E62528";
      case "core":
        return "#559EAA";
      case "collection":
        return "#101820";
      case "park-plaza":
        return "#252728";
      case "individuals":
        return "#f7b03d";
      default:
        return "#53565A";
    }
  };

  let style = props.className ? `${props.className} user-image` : "user-image";

  const onMoveEnd = () => {
    getCrop();
  };

  const onResizeEnd = () => {
    getCrop();
  };

  const getCrop = () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      const coordinates = cropperRef.current.getCoordinates();

      const { width, height } = coordinates;
      if (
        width < MIN_RESOLUTION.width ||
        height < MIN_RESOLUTION.height ||
        width > MAX_RESOLUTION.width ||
        height > MAX_RESOLUTION.height
      ) {
        setError(true);
        setErrorMessage(
          `The cropped area must be between ${MIN_RESOLUTION.width}x${MIN_RESOLUTION.height} and ${MAX_RESOLUTION.width}x${MAX_RESOLUTION.height}.`
        );
        return;
      }

      if (canvas) {
        const croppedImageDataUrl = canvas.toDataURL();
        setCroppedImage(croppedImageDataUrl);
      }
    }
  };

  const finishCrop = () => {
    setErrorMessage("");
    setError(false);
    setLoading(true);
    uploadFile(croppedImage);
  };

  const uploadFile = async (imageDataUrl) => {
    const url = `${serverUrl}/jsonapi/user/user/${props.uuid}/user_picture`;

    try {
      const blob = await fetch(imageDataUrl).then((res) => res.blob());

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/vnd.api+json",
          "Content-Disposition": `file; filename="cropped-image.png"`,
          "X-CSRF-Token": props.user.csrf_token,
        },
        credentials: "include",
        body: blob,
      });

      if (response.ok) {
        const data = await response.json();
        setErrorMessage("");
        setImage(null);
        setError(false);
        setLoading(false);
        setCroppedImage(null);
        setDialogModal(false);
        console.log("File uploaded successfully:", data);

        if (props.onUploadSuccess) {
          props.onUploadSuccess(data);
        }
      } else {
        const error = await response.text();
        console.error("Error uploading file:", error);
        setLoading(false);

        if (props.onUploadError) {
          props.onUploadError(error);
        } else {
          setError(true);
          setErrorMessage("Error: Failed to upload file");
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);

      if (props.onUploadError) {
        props.onUploadError(error);
      } else {
        setError(true);
        setErrorMessage("Error: Failed to upload file");
      }
    }
  };

  return (
    <>
      <Box className="user-image-container" sx={{ position: "relative" }}>
        <Avatar
          owner={props.owner}
          sub_variant={props.sub_variant}
          className={style}
          {...stringAvatar(props.name)}
          src={props.image}
        />
        {props.editable && (
          <IconButton
            className="user-image-edit"
            onClick={() => setDialogModal(true)}
            size="small"
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "40px",
              height: "40px",
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            <CameraAlt color="white" fontSize="12" />
          </IconButton>
        )}
      </Box>
      <Dialog
        open={uploadDialog}
        onClose={() => setDialogModal(false)}
        className="image-uploader-modal"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Container fluid>
            <Row className="">
              <Col>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUpload />}
                >
                  {image ? "Change Image" : "Upload Image"}
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Button>
              </Col>
            </Row>
            {image && (
              <Row className="mt-3">
                <Col>
                  <Cropper
                    ref={cropperRef}
                    className="cropper"
                    src={image}
                    aspectRatio={1}
                    style={{ height: "400px", width: "100%" }}
                    backgroundWrapperProps={{
                      scaleImage: false,
                    }}
                    imageRestriction={"fitArea"}
                    stencilProps={{
                      handlers: true,
                      lines: true,
                      aspectRatio: 1,
                      movable: true,
                    }}
                    defaultSize={{
                      width: MAX_RESOLUTION.width,
                      height: MAX_RESOLUTION.height,
                    }}
                    minWidth={MIN_RESOLUTION.width}
                    minHeight={MIN_RESOLUTION.height}
                    maxWidth={MAX_RESOLUTION.width}
                    maxHeight={MAX_RESOLUTION.height}
                    onReady={(cropper) => {
                      setTimeout(() => {
                        getCrop();
                      }, 1000);
                    }}
                    onMoveEnd={onMoveEnd}
                    onResizeEnd={onResizeEnd}
                  />
                </Col>
              </Row>
            )}
            {isError && (
              <Row className="mt-3">
                <Col>
                  <Alert variant="filled" severity="error">
                    {errorMessage}
                  </Alert>
                </Col>
              </Row>
            )}
            {image && (
              <Row className="mt-3">
                <Col>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setImage(null);
                        setCroppedImage(null);
                        setDialogModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isLoading}
                      variant="contained"
                      onClick={() => finishCrop()}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        `Crop & Save`
                      )}
                    </Button>
                  </Stack>
                </Col>
              </Row>
            )}
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
