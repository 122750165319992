export const default_reveal = {
  slideTransition: 'slide',
  slideLogo: '',
  slideLogoPosition: 'bottom-right',
  slideLockup: '',
  slideLockupPosition: 'bottom',
  slideLayout: { name: 'Blank', id: 'blank' },
  slideLayoutScheme: 'default',
  slideContentPosition: 'left',
  slideAudioData: '',
  slideBackgroundColor: '',
  slideBackgroundTransition: 'slide',
  slideBackgroundImage: '',
  slideBackgroundOpacity: 1,
  slideBackgroundRepeat: 'no-repeat',
  slideBackgroundPosition: 'center',
  slideBackgroundSize: 'cover',
  slideInnerBackgroundColor: '',
  slideInnerBackgroundImage: '',
  slideInnerBackgroundOpacity: 1,
  slideInnerBackgroundRepeat: 'no-repeat',
  slideInnerBackgroundPosition: 'center',
  slideInnerBackgroundSize: 'cover',
  slideAutoAnimate: false,
  slideOverlayOpacity: 0.7,
  slideOverlayWidth: 50,
  slideContentWidth: 30,
  slideOverlayColor: 'light',
  slideOverlayRGB: '255,255,255',
  slideChapterMarker: '',
  slideThumbnail: {},
  preventDuplicate: false,
  archContentAlignment: false,
  capacityTags: {
    guestRooms: true, 
    meetingRooms: true, 
    maxCap: true, 
    exhibitSpace: true, 
    suites: false, 
    familyRooms: false,
    restaurants: false,
    bars: false,
    spa: false,
    gym: false,
    pool: false,
    parking: false,
    freeWifi: false,
    breakfastBuffet: false,
    expressCheckout: false,
  },
};